import backgroundMobileVideo from "assets/videos/mobile-background-video.mp4";
import backgroundVideo from "assets/videos/background-video-placeholder.mp4";
import warsawBackgroundVideo from "assets/videos/background-video-placeholder-warsaw-v2.mp4";
import warsawBackgroundMobileVideo from "assets/videos/mobile-background-video-warsaw-v2.mp4";

import i18n from "i18n";

// siteUrl should be '/key', where key is the key of the location in the locations object
// this is because MainPage bases on the location param to render the correct location

export const getLocations = () => {
  return {
    wroclaw: {
      title: "WROCŁAW",

      // site data
      bookingUrl: {
        eng: "https://xl.plan4u.pl/pixel/xl/bookItNEW_EN.html",
        pl: "https://xl.plan4u.pl/pixel/xl/bookItNEW_PL.html",
      },
      backgroundVideo: backgroundVideo,
      backgroundMobileVideo: backgroundMobileVideo,

      vimeoBackgroundVideoURL:
        "https://player.vimeo.com/video/772339493?muted=1&autoplay=1&loop=1&title=0&byline=0&badge=0&autopause=0",
      vimeoMobileBackgroundVideoURL:
        "https://player.vimeo.com/video/772344269?muted=1&autoplay=1&loop=1&title=0&byline=0&badge=0&autopause=0",

      plan4uApiUrl: "https://xl.plan4u.pl/pixel/xl/api/v1",
      siteUrl: "/wroclaw",
      siteTitle: "Pixel XL Wroclaw",
      arenaWithCredits: false,
      googleMapsOptions: {
        latitude: 51.10697301550853,
        longitude: 17.03222937059691,
        zoom: 15,
      },
      prices: {
        smallGroupLowerPrice: "35",
        smallGroupHigherPrice: "50",
        bigGroupLowerPrice: "30",
        bigGroupHigherPrice: "45",
        arenaLowerPrice: "20",
        arenaHigherPrice: "30",
      },
      aboutUsImages: [
        "/images/wroclaw/about-us/about-picture-1.jpg",
        "/images/wroclaw/about-us/about-picture-2.jpg",
        "/images/wroclaw/about-us/about-picture-3.jpg",
        "/images/wroclaw/about-us/about-picture-4.v2.jpg",
        "/images/wroclaw/about-us/about-picture-5.jpg",
        "/images/wroclaw/about-us/about-picture-6.jpg",
      ],
      aboutUsMobileImages: [
        "/images/wroclaw/about-us/mobile-about-picture-1.jpg",
        "/images/wroclaw/about-us/mobile-about-picture-2.jpg",
        "/images/wroclaw/about-us/mobile-about-picture-3.jpg",
        "/images/wroclaw/about-us/mobile-about-picture-4.v2.jpg",
        "/images/wroclaw/about-us/mobile-about-picture-5.jpg",
        "/images/wroclaw/about-us/mobile-about-picture-6.jpg",
      ],
      cooperationSectionVisibility: true,
      integrationOfferVisibility: true,
      birthdayOfferVisibility: true,
      schoolOfferVisibility: true,
      documents: {
        pl: {
          companies: "/documents/wroclaw/companies-v5.pdf",
          schools: "/documents/wroclaw/schools-v7.pdf",
          birthdays: "/documents/wroclaw/birthday-v7.pdf",
        },
        eng: {
          companies: "/documents/wroclaw/companies-eng-v4.pdf",
          schools: "/documents/wroclaw/schools-eng-v4.pdf",
          birthdays: "/documents/wroclaw/birthday-eng-v4.pdf",
        },
      },
      address: "Świdnicka 12, 50-068 Wrocław",
      email: "wroclaw@pixel-xl.com",
      voucherPageUrl: "https://xl.plan4u.pl/pixel/xl/getVoucher.html",
    },
    katowice: {
      title: "KATOWICE",

      // site data
      siteUrl: "/katowice",
      bookingUrl: {
        eng: "https://xl.plan4u.pl/pixel/katowice/bookItNEW_EN.html",
        pl: "https://xl.plan4u.pl/pixel/katowice/bookItNEW_PL.html",
      },
      backgroundVideo: backgroundVideo,
      backgroundMobileVideo: backgroundMobileVideo,

      vimeoBackgroundVideoURL:
        "https://player.vimeo.com/video/772339493?muted=1&autoplay=1&loop=1&title=0&byline=0&badge=0&autopause=0",
      vimeoMobileBackgroundVideoURL:
        "https://player.vimeo.com/video/772344269?muted=1&autoplay=1&loop=1&title=0&byline=0&badge=0&autopause=0",

      plan4uApiUrl: "https://xl.plan4u.pl/pixel/katowice/api/v1",
      siteTitle: "Pixel XL Katowice",
      arenaWithCredits: true,
      googleMapsOptions: {
        latitude: 50.262872,
        longitude: 19.020316,
        zoom: 16,
      },
      prices: {
        smallGroupLowerPrice: "31",
        smallGroupHigherPrice: "45",
        bigGroupLowerPrice: "27",
        bigGroupHigherPrice: "40",
        arenaLowerPrice: "25",
        arenaHigherPrice: "35",
      },
      aboutUsImages: [
        "/images/wroclaw/about-us/about-picture-1.jpg",
        "/images/katowice/about-us/about-picture-2.v2.jpg",
        "/images/wroclaw/about-us/about-picture-3.jpg",
        "/images/katowice/about-us/about-picture-4.png",
        "/images/wroclaw/about-us/about-picture-5.jpg",
        "/images/katowice/about-us/about-picture-6.png",
      ],
      aboutUsMobileImages: [
        "/images/wroclaw/about-us/mobile-about-picture-1.jpg",
        "/images/katowice/about-us/mobile-about-picture-2.v2.jpg",
        "/images/wroclaw/about-us/mobile-about-picture-3.jpg",
        "/images/katowice/about-us/mobile-about-picture-4.png",
        "/images/wroclaw/about-us/mobile-about-picture-5.jpg",
        "/images/katowice/about-us/mobile-about-picture-6.png",
      ],
      cooperationSectionVisibility: true,
      integrationOfferVisibility: true,
      birthdayOfferVisibility: true,
      schoolOfferVisibility: true,
      documents: {
        pl: {
          companies: "/documents/katowice/companies-v3.pdf",
          schools: "/documents/katowice/schools-v4.pdf",
          birthdays: "/documents/katowice/birthday-v2.pdf",
        },
        eng: {
          companies: "/documents/katowice/companies-eng-v3.pdf",
          schools: "/documents/katowice/schools-eng-v4.pdf",
          birthdays: "/documents/katowice/birthday-eng-v2.pdf",
        },
      },
      address: "Sokolska 31, 40-086 Katowice",
      email: "katowice@pixel-xl.com",
      voucherPageUrl: "https://xl.plan4u.pl/pixel/katowice/getVoucher.html",
      classesCatalog: "/documents/katowice/classes-v3.pdf",
      classesStatue: "/documents/katowice/classes-statue.pdf",
    },
    warszawa: {
      title: "WARSZAWA",
      label: "Pixel XL BOX",

      // site data
      siteUrl: "/warszawa",
      bookingUrl: {
        eng: "https://xl.plan4u.pl/pixel/warszawa/bookItNEW_EN.html",
        pl: "https://xl.plan4u.pl/pixel/warszawa/bookItNEW_PL.html",
      },

      backgroundVideo: warsawBackgroundVideo,
      backgroundMobileVideo: warsawBackgroundMobileVideo,

      vimeoBackgroundVideoURL:
        "https://player.vimeo.com/video/1020127654?muted=1&autoplay=1&loop=1&title=0&byline=0&badge=0&autopause=0",
      vimeoMobileBackgroundVideoURL:
        "https://player.vimeo.com/video/1020128108?muted=1&autoplay=1&loop=1&title=0&byline=0&badge=0&autopause=0",

      plan4uApiUrl: "https://xl.plan4u.pl/pixel/warszawa/api/v1",
      siteTitle: "Pixel XL Warszawa",
      arenaWithCredits: true,
      googleMapsOptions: {
        latitude: 52.25625,
        longitude: 20.983733,
        zoom: 16,
      },
      prices: {
        smallGroupLowerPrice: "31",
        smallGroupHigherPrice: "45",
        bigGroupLowerPrice: "27",
        bigGroupHigherPrice: "40",
        smallGroupLowerPriceHalfHour: "20",
        smallGroupHigherPriceHalfHour: "30",
        bigGroupLowerPriceHalfHour: "15",
        bigGroupHigherPriceHalfHour: "25",
      },
      aboutUsImages: [
        "/images/warszawa/about-us/about-picture-1.v2.jpg",
        "/images/warszawa/about-us/about-picture-2.v2.jpg",
        "/images/warszawa/about-us/about-picture-3.v2.jpg",
        "/images/warszawa/about-us/about-picture-4.v2.jpg",
        "/images/warszawa/about-us/about-picture-5.v2.jpg",
        "/images/warszawa/about-us/about-picture-6.v2.jpg",
      ],
      aboutUsMobileImages: [
        "/images/warszawa/about-us/about-picture-1.v2.jpg",
        "/images/warszawa/about-us/about-picture-2.v2.jpg",
        "/images/warszawa/about-us/about-picture-3.v2.jpg",
        "/images/warszawa/about-us/about-picture-4.v2.jpg",
        "/images/warszawa/about-us/about-picture-5.v2.jpg",
        "/images/warszawa/about-us/about-picture-6.v2.jpg",
      ],
      cooperationSectionVisibility: true,
      integrationOfferVisibility: false,
      birthdayOfferVisibility: false,
      schoolOfferVisibility: true,
     
      documents: {
        pl: {
          schools: "/documents/warszawa/schools.pdf",
        },
        eng: {
          schools: "/documents/warszawa/schools-eng.pdf",
        },
      },

      address: "al. Jana Pawła II 82, 00-175 Warszawa",
      email: "warszawa@pixel-xl.com",
      voucherPageUrl: "https://xl.plan4u.pl/pixel/warszawa/getVoucher.html",
    },
    gdansk: {
      title: "TRÓJMIASTO",
      label: "Q4 2024",
      siteUrl: "/gdansk",
    },
    poznan: {
      title: "POZNAŃ",
      label: "2025",
      siteUrl: "/poznan",
    },
    krakow: {
      title: "KRAKÓW",
      label: "2025",
      siteUrl: "/krakow",
    },
  };
};
