import cn from "classnames";
import { Text } from "shared/components/Text";
import css from "./styles.module.scss";
import ArrowIcon from "assets/icons/arrow-icon.png";

export const Button = ({
  text,
  onClick,
  className,
  textClassname,
  disabled,
  withArrow,
}) => {
  return (
    <button
      onClick={onClick}
      className={cn(css.Button, className)}
      disabled={disabled}
    >
      <Text className={cn(css.text, textClassname)} type="white" bold>
        {text}
      </Text>
      {withArrow && (
        <img src={ArrowIcon} alt="Arrow icon" className={css.arrowIcon} />
      )}
    </button>
  );
};
