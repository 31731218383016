import { useParams } from "react-router-dom";
import { Header } from "shared/components/BasicHeader";
import { getLocations } from "features/LocationsPage/locations";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import css from "./styles.module.scss";
import schoolPagePicture from "assets/images/schools-header-img.jpg";
import { Text } from "shared/components/Text";
import watchIcon from "assets/images/schoolIcons/watch-icon.png";
import socksIcon from "assets/images/schoolIcons/socks-icon.png";
import handWithCashIcon from "assets/images/schoolIcons/hand-with-cash-icon.png";
import coffeeIcon from "assets/images/schoolIcons/coffe-icon.png";
import personIcon from "assets/images/schoolIcons/person-icon.png";
import squareIcon from "assets/images/schoolIcons/square-icon.png";
import gradientBar from "assets/images/gradient-bar.png";
import { Button } from "shared/components/Button";
import i18n from "i18n";
import { Footer } from "shared/components/Footer";
import cn from "classnames";
import { EventsPagesForm } from "features/events/EventsPagesForm";
import { useRef, useState } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { LoadingOverlay } from "shared/components/LoadingOverlay";

export const SchoolPage = () => {
  const { t } = useTranslation();
  const { city } = useParams();
  const locations = getLocations();
  const navigate = useNavigate();

  const topRef = useRef();

  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const footerScrollCallback = () => {
    topRef.current.scrollIntoView({ behavior: "smooth" });
    topRef.current.focus();
  };

  return (
    <div className={css.SchoolPage}>
      <div className={css.recaptchaContainer} id="recaptchaContainer" />
      {isSubmitLoading && <LoadingOverlay />}
      <Header city={city} cityName={locations[city]?.title || ""} />
      <div className={css.container}>
        <div className={css.flexContainer}>
          <div className={css.schoolPagePicture}>
            <img
              src={schoolPagePicture}
              alt="School page picture"
              className={css.schoolPagePicture}
              ref={topRef}
            />
            <div className={css.schoolPagePictureTextContainer}>
              <Text
                className={cn(css.schoolPagePictureText, css.lineOne)}
                type="white"
                bold
                secondaryFamily
              >
                {t("events.pictureTextLineOne")}
              </Text>
              <Text
                className={cn(css.schoolPagePictureText, css.lineTwo)}
                type="white"
                bold
                secondaryFamily
              >
                {t("events.pictureTextLineTwo")}
              </Text>
            </div>
          </div>
          <div className={css.schoolTitleContainer}>
            <Text
              className={css.schoolTitle}
              bold
              secondaryFamily
              alignCenter
              type="white"
            >
              {t("schoolPage.schoolTitle")}
            </Text>
          </div>
          <div>
            <Text className={css.schoolDescription} type="white" alignCenter>
              {t("schoolPage.schoolDescription")}
            </Text>
          </div>
          <div className={css.flexContainerFeatures}>
            <div className={css.flexContainerFeaturesLeftColumn}>
              <div className={css.features}>
                <img
                  src={handWithCashIcon}
                  className={css.icons}
                  alt="Hand with cash icon"
                />
                <div className={css.textContainer}>
                  <Text type="white" className={css.textTitleContainer} bold>
                    {t("schoolPage.titleFeatureOne")}
                  </Text>
                  <Text className={css.textDescriptionContainer} type="white">
                    {t("schoolPage.descriptionFeatureOne")}
                  </Text>
                </div>
              </div>
              <div className={css.features}>
                <img src={watchIcon} className={css.icons} alt="Watch icon" />
                <div className={css.textContainer}>
                  <Text type="white" className={css.textTitleContainer} bold>
                    {" "}
                    {t("schoolPage.titleFeatureTwo")}
                  </Text>
                  <Text className={css.textDescriptionContainer} type="white">
                    {t("schoolPage.descriptionFeatureTwo")}
                  </Text>
                </div>
              </div>
              <div className={css.features}>
                <img src={personIcon} className={css.icons} alt="Person icon" />
                <div className={css.textContainer}>
                  <Text type="white" className={css.textTitleContainer} bold>
                    {t("schoolPage.titleFeatureThree")}
                  </Text>
                  <Text className={css.textDescriptionContainer} type="white">
                    {t("schoolPage.descriptionFeatureThree")}
                  </Text>
                </div>
              </div>
            </div>
            <div className={css.flexContainerFeaturesRightColumn}>
              <div className={css.features}>
                <img src={socksIcon} className={css.icons} alt="Socks icon" />
                <div className={css.textContainer}>
                  <Text type="white" className={css.textTitleContainer} bold>
                    {t("schoolPage.titleFeatureFour")}
                  </Text>
                  <Text className={css.textDescriptionContainer} type="white">
                    {t("schoolPage.descriptionFeatureFour")}
                  </Text>
                </div>
              </div>
              {city !== "warszawa" && (
                <div className={css.features}>
                  <img
                    src={coffeeIcon}
                    className={css.icons}
                    alt="Coffee icon"
                  />
                  <div className={css.textContainer}>
                    <Text type="white" className={css.textTitleContainer} bold>
                      {t("schoolPage.titleFeatureFive")}
                    </Text>
                    <Text className={css.textDescriptionContainer} type="white">
                      {t("schoolPage.descriptionFeatureFive")}
                    </Text>
                  </div>
                </div>
              )}
              <div className={css.features}>
                <img src={squareIcon} className={css.icons} alt="Square icon" />
                <div className={css.textContainer}>
                  <Text type="white" className={css.textTitleContainer} bold>
                    {t("schoolPage.titleFeatureSix")}
                  </Text>
                  <Text className={css.textDescriptionContainer} type="white">
                    {t(`schoolPage.descriptionFeatureSix.${city}`)}
                  </Text>
                </div>
              </div>
            </div>
          </div>
          <img
            src={gradientBar}
            alt="gradient Bar"
            className={css.gradientBar}
          />
          <Text className={css.footerDescription} type="white" alignCenter>
            {t(`schoolPage.footerDescription.${city}`)}
          </Text>
          <div className={css.buttonSection}>
            <Button
              text={t("events.bookingButton")}
              className={css.rightButton}
              onClick={() => {
                navigate(`/${city}/booking`);
              }}
            ></Button>
            <a href={locations[city].documents[i18n.language].schools} download>
              <Text className={css.linkText} type="white" bold>
                {t("events.pdfButton")}
              </Text>
            </a>
          </div>
          <img
            src={gradientBar}
            alt="gradient Bar"
            className={css.gradientBar}
          />
          <GoogleReCaptchaProvider
            reCaptchaKey={process.env.REACT_APP_EVENTS_RECAPTCHA_KEY}
            container={{
              element: "recaptchaContainer",
              parameters: {
                badge: "bottomleft",
                size: "invisible",
              },
            }}
          >
            <EventsPagesForm
              setIsSubmitLoading={setIsSubmitLoading}
              pageType="School"
              city={city}
            ></EventsPagesForm>
          </GoogleReCaptchaProvider>
        </div>
      </div>
      <Footer scrollCallback={footerScrollCallback} />
    </div>
  );
};
