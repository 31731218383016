import i18n from "i18n";

export const getEventsConfig = (city) => {
  const eventsConfig = {
    wroclaw: {
      birthday: [
        {
          name: "PIXEL",
          priceMonToThr: "549 PLN",
          priceFriToSun: "779 PLN",
          features: [
            { value: 1, label: i18n.t("birthdayPage.matNumber") },
            { value: true, label: i18n.t("birthdayPage.bottleOfWater") },
            { value: true, label: i18n.t("birthdayPage.snackSet") },
            { value: true, label: i18n.t("birthdayPage.nonSlipSocksChild") },
            { value: true, label: i18n.t("birthdayPage.printedInvitations") },
            { value: true, label: i18n.t("birthdayPage.tableware") },
            { value: true, label: i18n.t("birthdayPage.certificate") },
            { value: false, label: i18n.t("birthdayPage.trainerSupervision") },
            { value: false, label: i18n.t("birthdayPage.bottleOfJuice") },
            { value: false, label: i18n.t("birthdayPage.nonSlipSocksGuest") },
            { value: false, label: i18n.t("birthdayPage.voucher") },
            { value: 6, label: i18n.t("birthdayPage.numberOfPlayers") },
          ]
        },
        {
          name: "XL",
          priceMonToThr: "899 PLN",
          priceFriToSun: "1199 PLN",
          features: [
            { value: 2, label: i18n.t("birthdayPage.matNumber") },
            { value: true, label: i18n.t("birthdayPage.bottleOfWater") },
            { value: true, label: i18n.t("birthdayPage.snackSet") },
            { value: true, label: i18n.t("birthdayPage.nonSlipSocksChild") },
            { value: true, label: i18n.t("birthdayPage.printedInvitations") },
            { value: true, label: i18n.t("birthdayPage.tableware") },
            { value: true, label: i18n.t("birthdayPage.certificate") },
            { value: true, label: i18n.t("birthdayPage.trainerSupervision") },
            { value: true, label: i18n.t("birthdayPage.bottleOfJuice") },
            { value: false, label: i18n.t("birthdayPage.nonSlipSocksGuest") },
            { value: false, label: i18n.t("birthdayPage.voucher") },
            { value: 12, label: i18n.t("birthdayPage.numberOfPlayers") },
          ]
        },
        {
          name: "XXL",
          priceMonToThr: "2299 PLN",
          priceFriToSun: "2899 PLN",
          features: [
            { value: 5, label: i18n.t("birthdayPage.matNumber") },
            { value: true, label: i18n.t("birthdayPage.bottleOfWater") },
            { value: true, label: i18n.t("birthdayPage.snackSet") },
            { value: true, label: i18n.t("birthdayPage.nonSlipSocksChild") },
            { value: true, label: i18n.t("birthdayPage.printedInvitations") },
            { value: true, label: i18n.t("birthdayPage.tableware") },
            { value: true, label: i18n.t("birthdayPage.certificate") },
            { value: true, label: i18n.t("birthdayPage.trainerSupervision") },
            { value: true, label: i18n.t("birthdayPage.bottleOfJuice") },
            { value: true, label: i18n.t("birthdayPage.nonSlipSocksGuest") },
            { value: true, label: i18n.t("birthdayPage.voucher") },
            { value: 50, label: i18n.t("birthdayPage.numberOfPlayers") }
          ]
        }
      ],
      integration: [
        {
          packageName: i18n.t("integrationPage.packageL"),
          packagePrice: "699 PLN",
          packageMats: i18n.t("integrationPage.oneMat"),
          packagesPlayers: 10
        },
        {
          packageName: i18n.t("integrationPage.packageL+"),
          packagePrice: "999 PLN",
          packageMats: i18n.t("integrationPage.oneMatAndArena"),
          packagesPlayers: 16
        },
        {
          packageName: i18n.t("integrationPage.packageXL"),
          packagePrice: "1299 PLN",
          packageMats: i18n.t("integrationPage.twoMats"),
          packagesPlayers: 25
        },
        {
          packageName: i18n.t("integrationPage.packageXL+"),
          packagePrice: "1899 PLN",
          packageMats: i18n.t("integrationPage.twoMatsAndArena"),
          packagesPlayers: 31
        },
        {
          packageName: i18n.t("integrationPage.packageXXL"),
          packagePrice: "2899 PLN",
          packageMats: i18n.t("integrationPage.wholeVenue"),
          packagesPlayers: 50
        }
      ]
    },
    katowice: {
      birthday: [
        {
          name: "PIXEL",
          priceMonToThr: "549 PLN",
          priceFriToSun: "719 PLN",
          features: [
            { value: 1, label: i18n.t("birthdayPage.matNumber") },
            { value: true, label: i18n.t("birthdayPage.bottleOfWater") },
            { value: true, label: i18n.t("birthdayPage.snackSet") },
            { value: true, label: i18n.t("birthdayPage.nonSlipSocksChild") },
            { value: true, label: i18n.t("birthdayPage.printedInvitations") },
            { value: true, label: i18n.t("birthdayPage.tableware") },
            { value: true, label: i18n.t("birthdayPage.certificate") },
            { value: false, label: i18n.t("birthdayPage.trainerSupervision") },
            { value: false, label: i18n.t("birthdayPage.bottleOfJuice") },
            { value: false, label: i18n.t("birthdayPage.nonSlipSocksGuest") },
            { value: false, label: i18n.t("birthdayPage.voucher") },
            { value: 6, label: i18n.t("birthdayPage.numberOfPlayers") },
          ]
        },
        {
          name: "L",
          priceMonToThr: "899 PLN",
          priceFriToSun: "1099 PLN",
          features: [
            { value: 2, label: i18n.t("birthdayPage.matNumber") },
            { value: true, label: i18n.t("birthdayPage.bottleOfWater") },
            { value: true, label: i18n.t("birthdayPage.snackSet") },
            { value: true, label: i18n.t("birthdayPage.nonSlipSocksChild") },
            { value: true, label: i18n.t("birthdayPage.printedInvitations") },
            { value: true, label: i18n.t("birthdayPage.tableware") },
            { value: true, label: i18n.t("birthdayPage.certificate") },
            { value: true, label: i18n.t("birthdayPage.trainerSupervision") },
            { value: true, label: i18n.t("birthdayPage.bottleOfJuice") },
            { value: false, label: i18n.t("birthdayPage.nonSlipSocksGuest") },
            { value: false, label: i18n.t("birthdayPage.voucher") },
            { value: 12, label: i18n.t("birthdayPage.numberOfPlayers") },
          ]
        },
        {
          name: "XL",
          priceMonToThr: "1349 PLN",
          priceFriToSun: "1549 PLN",
          features: [
            { value: 3, label: i18n.t("birthdayPage.matNumber") },
            { value: true, label: i18n.t("birthdayPage.bottleOfWater") },
            { value: true, label: i18n.t("birthdayPage.snackSet") },
            { value: true, label: i18n.t("birthdayPage.nonSlipSocksChild") },
            { value: true, label: i18n.t("birthdayPage.printedInvitations") },
            { value: true, label: i18n.t("birthdayPage.tableware") },
            { value: true, label: i18n.t("birthdayPage.certificate") },
            { value: true, label: i18n.t("birthdayPage.trainerSupervision") },
            { value: true, label: i18n.t("birthdayPage.bottleOfJuice") },
            { value: true, label: i18n.t("birthdayPage.nonSlipSocksGuest") },
            { value: false, label: i18n.t("birthdayPage.voucher") },
            { value: 18, label: i18n.t("birthdayPage.numberOfPlayers") },
          ]
        },
        {
          name: "XXL",
          priceMonToThr: "1799 PLN",
          priceFriToSun: "2089 PLN",
          features: [
            { value: 4, label: i18n.t("birthdayPage.matNumber") },
            { value: true, label: i18n.t("birthdayPage.bottleOfWater") },
            { value: true, label: i18n.t("birthdayPage.snackSet") },
            { value: true, label: i18n.t("birthdayPage.nonSlipSocksChild") },
            { value: true, label: i18n.t("birthdayPage.printedInvitations") },
            { value: true, label: i18n.t("birthdayPage.tableware") },
            { value: true, label: i18n.t("birthdayPage.certificate") },
            { value: true, label: i18n.t("birthdayPage.trainerSupervision") },
            { value: true, label: i18n.t("birthdayPage.bottleOfJuice") },
            { value: true, label: i18n.t("birthdayPage.nonSlipSocksGuest") },
            { value: true, label: i18n.t("birthdayPage.voucher") },
            { value: 24, label: i18n.t("birthdayPage.numberOfPlayers") },
          ]
        }
      ],
      integration: [
        {
          packageName: i18n.t("integrationPage.packageL"),
          packagePrice: "699 PLN",
          packageMats: i18n.t("integrationPage.oneMat"),
          packagesPlayers: 8
        },
        {
          packageName: i18n.t("integrationPage.packageL+"),
          packagePrice: "1299 PLN",
          packageMats: i18n.t("integrationPage.twoMats"),
          packagesPlayers: 16
        },
        {
          packageName: i18n.t("integrationPage.packageXL"),
          packagePrice: "1899 PLN",
          packageMats: i18n.t("integrationPage.threeMats"),
          packagesPlayers: 24
        },
        {
          packageName: i18n.t("integrationPage.packageXL+"),
          packagePrice: "2449 PLN",
          packageMats: i18n.t("integrationPage.fourMats"),
          packagesPlayers: 32
        },
        {
        packageName: i18n.t("integrationPage.packageXXL"),
          packagePrice: "2999 PLN",
          packageMats: i18n.t("integrationPage.fiveMats"),
          packagesPlayers: 40
        },
        {
          packageName: i18n.t("integrationPage.packageXXL+"),
          packagePrice: i18n.t("integrationPage.individualPricing"),
          packageMats: i18n.t("integrationPage.wholeVenue"),
          packagesPlayers: ""
        }
      ]
    }
  }

  return eventsConfig[city];
}
