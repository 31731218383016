import { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { MainPage } from "features/MainPage";
import { BirthdayPage } from "features/events/BirthdayPage";
import { IntegrationPage } from "features/events/IntegrationPage";
import { SchoolPage } from "features/events/SchoolPage";
import { BookingPage } from "features/BookingPage";
import { VoucherPage } from "features/VoucherPage";
import { UserDataFormPage } from "features/UserDataFormPage";
import { Menu } from "features/Menu";
import { LocationsPage } from "features/LocationsPage";
import { LocationInProgressPage } from "features/LocationInProgressPage";
import { ClassesPage } from "features/ClassesPage";

import css from "./styles.module.scss";
import { LocationInProgressWithoutCountdownPage } from "features/LocationInProgressWithoutCountdownPage";

export const App = () => {
  return (
    <BrowserRouter>
      <div className={css.App}>
        <Routes>
          <Route path="/" element={<LocationsPage />} />
          <Route path="/cancel" element={<LocationsPage />} />
          <Route
            path="/success"
            element={<LocationsPage isSuccessInUrl={true} />}
          />
          {[
            "/gdansk/booking",
            "/gdansk/cancel",
            "/gdansk/birthday",
            "/gdansk/integration",
            "/gdansk/school",
            "/gdansk/vouchers",
            "/gdansk/form",
            "/gdansk/menu",
            "/gdansk/*",
          ].map((path) => (
            <Route
              key="Route"
              path={path}
              element={<LocationInProgressWithoutCountdownPage city="gdansk" />}
            />
          ))}
          {[
            "/poznan/booking",
            "/poznan/cancel",
            "/poznan/birthday",
            "/poznan/integration",
            "/poznan/school",
            "/poznan/vouchers",
            "/poznan/form",
            "/poznan/menu",
            "/poznan/*",
          ].map((path) => (
            <Route
              key="Route"
              path={path}
              element={<LocationInProgressWithoutCountdownPage city="poznan" />}
            />
          ))}
          {[
            "/krakow/booking",
            "/krakow/cancel",
            "/krakow/birthday",
            "/krakow/integration",
            "/krakow/school",
            "/krakow/vouchers",
            "/krakow/form",
            "/krakow/menu",
            "/krakow/*",
          ].map((path) => (
            <Route
              key="Route"
              path={path}
              element={<LocationInProgressWithoutCountdownPage city="krakow" />}
            />
          ))}
          <Route path="/:city" element={<MainPage />} />
          <Route path="/:city/:reservationStatus" element={<MainPage />} />
          <Route path="/:city/cancel" element={<MainPage />} />
          <Route path="/:city/booking" element={<BookingPage />} />
          <Route path="/:city/birthday" element={<BirthdayPage />} />
          <Route path="/:city/integration" element={<IntegrationPage />} />
          <Route path="/:city/school" element={<SchoolPage />} />
          <Route path="/:city/vouchers" element={<VoucherPage />} />
          <Route path="/:city/form" element={<UserDataFormPage />} />
          <Route path="/:city/menu" element={<Menu />} />
          <Route path="/menu" element={<Menu />} />
          <Route path="/form" element={<UserDataFormPage />} />
          <Route
            path="/katowice/classes"
            element={<ClassesPage city={"katowice"} />}
          />
        </Routes>
      </div>
    </BrowserRouter>
  );
};
