import cn from "classnames";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import AwesomeSlider from "react-awesome-slider";
import AwesomeSliderStyles from "react-awesome-slider/src/styles";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import { useParams } from "react-router-dom";

import { Text } from "shared/components/Text";
import reviewBorder from "assets/images/review-border.png";
import reviewUpperQuotationMark from "assets/images/review-upper-quotation-mark.png";
import reviewLowerQuotationMark from "assets/images/review-lower-quotation-mark.png";

import css from "./styles.module.scss";

const AUTOPLAY_INTERVAL_MOBILE = 10000;
const AUTOPLAY_INTERVAL_DESKTOP = 30000;

export const ReviewSection = ({ passedRef }) => {
  const { t, i18n } = useTranslation();
  const { city } = useParams();

  const content = useMemo(() => {
    return t(`reviewSection.reviews.${city}`, {
      returnObjects: true,
    });
  }, [i18n.language]);

  const AutoplaySlider = withAutoplay(AwesomeSlider);

  const isMobile = window.matchMedia("(max-width: 1060px)").matches;

  const groupedContent = useMemo(() => {
    const grouped = [];
    for (let i = 0; i < content.length; i += 3) {
      grouped.push(content.slice(i, i + 3));
    }
    return grouped;
  }, [content]);

  return (
    <div ref={passedRef} className={css.ReviewSection}>
      <div className={css.contentBox}>
        <Text type="white" className={css.title} bold secondaryFamily>
          {t("reviewSection.title")}
        </Text>
        <AutoplaySlider
          cssModule={AwesomeSliderStyles}
          className={css.contentContainer}
          bullets={isMobile}
          organicArrows={true}
          play={true}
          interval={
            isMobile ? AUTOPLAY_INTERVAL_MOBILE : AUTOPLAY_INTERVAL_DESKTOP
          }
          mobileTouch={true}
        >
          {(isMobile ? content : groupedContent).map((elements, i) => (
            <div
              key={i}
              className={
                isMobile ? css.contentItemContainer : css.groupContainer
              }
            >
              {(isMobile ? [elements] : elements).map((element, j) => (
                <div key={j} className={css.contentItemContainer}>
                  <img
                    src={reviewBorder}
                    alt="review border"
                    title="Review border"
                    className={css.border}
                  />
                  <Text type="white" className={css.reviewText}>
                    {element.content}
                  </Text>
                  <Text type="white" className={css.reviewAuthor} bold>
                    {element.author}
                  </Text>
                  <img
                    src={reviewUpperQuotationMark}
                    alt="review upper quotation mark"
                    title="Review quotation mark"
                    className={css.upperQuotationMark}
                  />
                  <img
                    src={reviewLowerQuotationMark}
                    alt="review lower quotation mark"
                    title="Review quotation mark"
                    className={css.lowerQuotationMark}
                  />
                </div>
              ))}
            </div>
          ))}
        </AutoplaySlider>
      </div>
    </div>
  );
};
