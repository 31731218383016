import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import cn from "classnames";

import { Text } from "shared/components/Text";
import { Button } from "shared/components/Button";
import sectionSeparator from "assets/images/section-separator.png";
import { ReactComponent as SmallGroupIcon } from "assets/icons/small-group.svg";
import { ReactComponent as BigGroupIcon } from "assets/icons/big-group.svg";
import SinglePersonIcon from "assets/icons/single-person.png";
import titleGlow from "assets/images/title-glow.png";

import css from "./styles.module.scss";

export const PriceListSection = ({
  passedRef,
  prices,
  bookingUrl,
  city,
  locations,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const content = t("priceListSection.content", {
    returnObjects: true,
  });

  const contentAfterPriceList = t(
    `priceListSection.contentAfterPriceList.${city}`,
    {
      returnObjects: true,
    }
  );

  return (
    <div ref={passedRef} className={css.PriceListSection}>
      <Text type="white" className={css.title} bold secondaryFamily>
        {t("priceListSection.title")}
      </Text>
      {city === "warszawa" && (
        <Text type="white" className={css.subTitle} bold>
          {t("priceListSection.subTitle")}
        </Text>
      )}
      {content.map((el) => (
        <Text key={el} type="lighterPink" className={css.content}>
          {el}
        </Text>
      ))}

      <div className={css.priceList}>
        <div className={css.priceListElement}>
          <SmallGroupIcon className={css.priceListIcon} />
          <Text type="pink" className={css.priceListSmallerText} bold>
            {t("priceListSection.smallGroups")}
          </Text>
          <Text type="white" className={css.priceListBiggerText} bold>
            {t("priceListSection.price", {
              price: prices.smallGroupHigherPrice,
            })}
          </Text>
          <div className={css.smallSeparator} />
          <Text type="lightGreen" className={css.priceListMediumText} bold>
            HAPPY HOURS:
          </Text>
          <Text type="lightGreen" className={css.priceListSmallerText} bold>
            {t("priceListSection.timeDistinction")}
          </Text>
          <Text type="white" className={css.priceListBiggerText} bold>
            {t("priceListSection.price", {
              price: prices.smallGroupLowerPrice,
            })}
          </Text>
        </div>
        <div className={css.priceListElement}>
          <BigGroupIcon className={css.priceListIcon} />
          <Text type="pink" className={css.priceListSmallerText} bold>
            {t("priceListSection.bigGroups")}
          </Text>
          <Text type="white" className={css.priceListBiggerText} bold>
            {t("priceListSection.price", {
              price: prices.bigGroupHigherPrice,
            })}
          </Text>
          <div className={css.smallSeparator} />
          <Text type="lightGreen" className={css.priceListMediumText} bold>
            HAPPY HOURS:
          </Text>
          <Text type="lightGreen" className={css.priceListSmallerText} bold>
            {t("priceListSection.timeDistinction")}
          </Text>
          <Text type="white" className={css.priceListBiggerText} bold>
            {t("priceListSection.price", {
              price: prices.bigGroupLowerPrice,
            })}
          </Text>
        </div>
        {(!locations[city] || !locations[city].arenaWithCredits) && (
          <div className={css.priceListElement}>
            <img
              src={SinglePersonIcon}
              alt="single person icon"
              className={css.priceListIcon}
            />
            <Text type="pink" className={css.priceListSmallerText} bold>
              {t("priceListSection.arena")}
            </Text>
            <Text type="white" className={css.priceListBiggerText} bold>
              {t("priceListSection.price", {
                price: prices.arenaHigherPrice,
              })}
            </Text>
            <div className={css.smallSeparator} />
            <Text type="lightGreen" className={css.priceListMediumText} bold>
              HAPPY HOURS:
            </Text>
            <Text type="lightGreen" className={css.priceListSmallerText} bold>
              {t("priceListSection.timeDistinction")}
            </Text>
            <Text type="white" className={css.priceListBiggerText} bold>
              {t("priceListSection.price", {
                price: prices.arenaLowerPrice,
              })}
            </Text>
          </div>
        )}
      </div>
      {city === "warszawa" && (
        <Text type="white" className={css.subTitle} bold>
          {t("priceListSection.secondSubTitle")}
        </Text>
      )}
      {city === "warszawa" && (
        <div className={css.priceList}>
          <div className={css.priceListElement}>
            <SmallGroupIcon className={css.priceListIcon} />
            <Text type="pink" className={css.priceListSmallerText} bold>
              {t("priceListSection.smallGroups")}
            </Text>
            <Text type="white" className={css.priceListBiggerText} bold>
              {t("priceListSection.price", {
                price: prices.smallGroupHigherPriceHalfHour,
              })}
            </Text>
            <div className={css.smallSeparator} />
            <Text type="lightGreen" className={css.priceListMediumText} bold>
              HAPPY HOURS:
            </Text>
            <Text type="lightGreen" className={css.priceListSmallerText} bold>
              {t("priceListSection.timeDistinction")}
            </Text>
            <Text type="white" className={css.priceListBiggerText} bold>
              {t("priceListSection.price", {
                price: prices.smallGroupLowerPriceHalfHour,
              })}
            </Text>
          </div>
          <div className={css.priceListElement}>
            <BigGroupIcon className={css.priceListIcon} />
            <Text type="pink" className={css.priceListSmallerText} bold>
              {t("priceListSection.bigGroups")}
            </Text>
            <Text type="white" className={css.priceListBiggerText} bold>
              {t("priceListSection.price", {
                price: prices.bigGroupHigherPriceHalfHour,
              })}
            </Text>
            <div className={css.smallSeparator} />
            <Text type="lightGreen" className={css.priceListMediumText} bold>
              HAPPY HOURS:
            </Text>
            <Text type="lightGreen" className={css.priceListSmallerText} bold>
              {t("priceListSection.timeDistinction")}
            </Text>
            <Text type="white" className={css.priceListBiggerText} bold>
              {t("priceListSection.price", {
                price: prices.bigGroupLowerPriceHalfHour,
              })}
            </Text>
          </div>
        </div>
      )}
      <Button
        text={t("shared.buyTicket")}
        className={css.buyTicketButton}
        onClick={() => {
          navigate(bookingUrl);
        }}
        withArrow
      />
      {contentAfterPriceList.map((el) => (
        <Text key={el} type="white" className={css.content}>
          {el}
        </Text>
      ))}
    </div>
  );
};
