import cn from "classnames";
import { useTranslation } from "react-i18next";
import {
  useRef,
  useEffect,
  useState,
  Suspense,
  useCallback,
  useMemo,
} from "react";
import {
  useParams,
  useNavigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

import { Header } from "features/MainPage/Header";
import { MobileMenu } from "features/MainPage/MobileMenu";
import { Footer } from "shared/components/Footer";
import { Text } from "shared/components/Text";
import { Button } from "shared/components/Button";
import firstShadow from "assets/images/shadow-1.png";
import secondShadow from "assets/images/shadow-2.png";
import { defaultMapOptions } from "shared/constants";
import { getLocations } from "features/LocationsPage/locations";

import { GoogleMaps } from "./GoogleMaps";
import { AboutSection } from "./sections/AboutSection";
import { PriceListSection } from "./sections/PriceListSection";
import { CareerSection } from "./sections/CareerSection";
import { CooperationSection } from "./sections/CooperationSection";
import { FAQSection } from "./sections/FAQSection";
import { ContactSection } from "./sections/ContactSection";
import { VoucherSection } from "./sections/VoucherSection";
import css from "./styles.module.scss";
import { Toast } from "./Toast";
import { ReviewSection } from "./sections/ReviewSection";
import { ResetLocationButton } from "shared/components/ResetLocationButton";
import { LanguageChooser } from "shared/components/LanguageChooser";
import { NewsletterSection } from "./sections/NewsletterSection";
import { LoadingOverlay } from "shared/components/LoadingOverlay";
import { ClassesSection } from "./sections/ClassesSection";

const isIos = () => {
  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "MacIntel",
      "iPod",
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
};

export const MainPage = () => {
  const { t, i18n } = useTranslation();

  const { city, reservationStatus } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const locations = useMemo(() => {
    return getLocations();
  }, [i18n.language]);

  const currentCityConfig = useMemo(() => {
    return locations[city];
  }, [city]);

  const backgroundVideo = useMemo(() => {
    return locations[city].backgroundVideo;
  }, [locations, city]);

  const backgroundMobileVideo = useMemo(() => {
    return locations[city].backgroundMobileVideo;
  }, [locations, city]);

  const vimeoBackgroundVideoURL = useMemo(() => {
    return locations[city].vimeoBackgroundVideoURL;
  }, [locations, city]);

  const vimeoMobileBackgroundVideoURL = useMemo(() => {
    return locations[city].vimeoMobileBackgroundVideoURL;
  }, [locations, city]);

  const isCooperationSectionVisible = useMemo(() => {
    return locations[city].cooperationSectionVisibility;
  }, [locations, city]);

  const location = useLocation();
  const shouldShowClassesSection = !!locations[city].classesCatalog;

  const navigate = useNavigate();

  const [mobileMenuVisibility, setMobileMenuVisibility] = useState();
  const [toastText, setToastText] = useState("");
  const [requestFailed, setRequestFailed] = useState(false);
  const [mapOptions, setMapOptions] = useState(defaultMapOptions);
  const [isNewsletterRequestLoading, setIsNewsletterRequestLoading] =
    useState(false);

  const homeRef = useRef();
  const aboutRef = useRef();
  const priceListRef = useRef();
  const careerRef = useRef();
  const cooperationRef = useRef();
  const classesRef = useRef();
  const voucherRef = useRef();
  const contactRef = useRef();
  const faqRef = useRef();
  const reviewsRef = useRef();

  useEffect(() => {
    const bookingId = searchParams.get("booking_id");
    const personId = searchParams.get("person_id");

    const cancelReservation = async () => {
      const plan4uApiUrl = currentCityConfig.plan4uApiUrl;
      const response = await fetch(
        `${plan4uApiUrl}/o_cancelBooking/${bookingId}/${personId}`
      );
      const json = await response.json();
      if (json.status === "success") {
        setToastText(t("shared.cancelled"));
        setRequestFailed(false);
      } else {
        setToastText(t("shared.cancelError"));
        setRequestFailed(true);
      }
    };
    if (bookingId && personId && currentCityConfig.plan4uApiUrl) {
      cancelReservation();
    }
  }, [searchParams, currentCityConfig]);

  useEffect(() => {
    const getBasketValueAndSendPurchaseEvent = async () => {
      const plan4uApiUrl = currentCityConfig.plan4uApiUrl;
      const basketId = searchParams.get("bGUID");

      if (!basketId) {
        setToastText(t("shared.success"));
        return;
      }

      let response;
      let amount;
      let id;
      try {
        response = await (
          await fetch(`${plan4uApiUrl}/o_basketInfo/${basketId}`, {
            method: "GET",
          })
        ).json();
        amount = response.data[0].amount / 100;
        id = response.data[0].id;
      } catch (e) {
        const err = `Error while retrieving basket: ${e}`;
        console.error(err);
        setToastText(t("shared.success"));
        return;
      }

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "purchase",
        ecommerce: {
          value: amount,
          currency: "PLN",
          transaction_id: id,
          coupon: null,
        },
      });

      setToastText(t("shared.success"));
    };

    if (reservationStatus === "success" && currentCityConfig) {
      getBasketValueAndSendPurchaseEvent();
    }
  }, [reservationStatus, currentCityConfig]);

  useEffect(() => {
    // checking if currentCityConfig would be undefined
    if (!locations[city]) {
      navigate("/");
      return;
    }

    homeRef.current.scrollIntoView();
    document.title = currentCityConfig.siteTitle;

    const copiedMapOptions = { ...defaultMapOptions };
    copiedMapOptions.options.zoom = currentCityConfig.googleMapsOptions.zoom;
    copiedMapOptions.options.center.lat =
      currentCityConfig.googleMapsOptions.latitude;
    copiedMapOptions.options.center.lng =
      currentCityConfig.googleMapsOptions.longitude;
    copiedMapOptions.markerOptions.position.lat =
      currentCityConfig.googleMapsOptions.latitude;
    copiedMapOptions.markerOptions.position.lng =
      currentCityConfig.googleMapsOptions.longitude;
    setMapOptions(copiedMapOptions);
  }, [currentCityConfig, navigate]);

  const sections = useMemo(() => {
    let sectionsArray = [
      { title: t("header.home"), ref: homeRef },
      { title: t("header.aboutUs"), ref: aboutRef },
      { title: t("header.priceList"), ref: priceListRef },
      { title: t("header.vouchers"), ref: voucherRef },
      { title: t("header.cooperation"), ref: cooperationRef },
      { title: t("header.classes"), ref: classesRef },
      { title: t("header.reviews"), ref: reviewsRef },
      { title: t("header.faq"), ref: faqRef },
      { title: t("header.career"), ref: careerRef },
      { title: t("header.contact"), ref: contactRef },
    ];

    if (!isCooperationSectionVisible) {
      sectionsArray = sectionsArray.filter(
        (section) => section.ref !== cooperationRef
      );
    }

    if (!shouldShowClassesSection) {
      sectionsArray = sectionsArray.filter(
        (section) => section.ref !== classesRef
      );
    }

    return sectionsArray;
  }, [i18n.language]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, true);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [sections]);

  const selectSection = (title, ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleScroll = useCallback(
    (event) => {
      for (let i = sections.length - 1; i >= 0; --i) {
        if (!sections[i].ref) {
          continue;
        }
        const current = sections[i].ref.current;

        if (
          current &&
          current.getBoundingClientRect().top <
            Math.round(window.innerHeight / 2)
        ) {
          setSelectedSection(sections[i].title);
          break;
        }
      }
    },
    [sections]
  );

  const homeContent = t("homeSection.content", {
    returnObjects: true,
  });

  const [selectedSection, setSelectedSection] = useState(sections[0].title);
  const [isVideoLoading, setIsVideoLoading] = useState(true);
  const isMobile = window.matchMedia("(max-width: 990px)").matches;

  if (!currentCityConfig) return <></>;

  return (
    <div className={css.MainPage}>
      <Header
        sections={sections}
        selectedSection={selectedSection}
        selectSection={selectSection}
        setMobileMenuVisibility={setMobileMenuVisibility}
      />
      <div className={css.languageChooserContainer}>
        <LanguageChooser
          className={css.languageChooser}
          shouldScrollUp
          borderColor="blue"
        />
      </div>
      <div className={css.recaptchaContainer} id="recaptchaContainer" />
      {toastText && <Toast text={toastText} failed={requestFailed} />}
      {isFinite(mobileMenuVisibility) && (
        <MobileMenu
          mobileMenuVisibility={mobileMenuVisibility}
          setMobileMenuVisibility={setMobileMenuVisibility}
          sections={sections}
          selectedSection={selectedSection}
          selectSection={selectSection}
          cityName={currentCityConfig.title}
        />
      )}
      <div ref={homeRef} className={css.home}>
        <div
          className={cn(css.homeBackground, { [css.hide]: !isVideoLoading })}
        >
          {!isMobile && <div className={css.spinner} />}
        </div>
        {isIos() ? (
          <div className={css.iframeContainer}>
            <iframe
              src={
                isMobile
                  ? vimeoMobileBackgroundVideoURL
                  : vimeoBackgroundVideoURL
              }
              data-read="true"
              frameBorder="0"
              allow="autoplay"
              // allowFullScreen
              className={css.homeVideo}
              onCanPlay={() => setIsVideoLoading(false)}
            ></iframe>
            <div className={css.iframeBlend} />
          </div>
        ) : (
          <video
            loop
            autoPlay
            muted
            className={css.homeVideo}
            playsInline
            // poster={backgroundPlaceholder}
            onCanPlay={() => setIsVideoLoading(false)}
            src={isMobile ? backgroundMobileVideo : backgroundVideo}
          >
            <source
              src={isMobile ? backgroundMobileVideo : backgroundVideo}
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        )}
        <div className={css.homeContentContainer}>
          <Text
            type="white"
            className={css.homeContentTitle}
            secondaryFamily
            bold
          >
            {t(`homeSection.title.${city}`)}
          </Text>
          {homeContent.map((el) => (
            <Text key={el} type="lightBlue" className={css.homeContent}>
              {el}
            </Text>
          ))}
          <Button
            text={t("shared.buyTicket")}
            onClick={() => {
              navigate(`/${city}/booking`);
            }}
            className={css.buyTicketButton}
            withArrow
          />
          <div className={css.resetLocationButtonContainer}>
            <ResetLocationButton
              cityName={currentCityConfig.title}
              className={css.resetLocationButton}
              textClassName={css.resetLocationButtonText}
              locationPinClassName={css.resetLocationButtonPin}
            />
          </div>
        </div>
      </div>
      <div className={css.relativeContainer}>
        {isNewsletterRequestLoading && <LoadingOverlay />}
        <div className={css.mainContent}>
          <img
            title="Shadow decoration"
            src={secondShadow}
            alt="second shadow decoration"
            className={css.secondShadow}
          />
          <AboutSection
            passedRef={aboutRef}
            images={currentCityConfig.aboutUsImages}
            mobileImages={currentCityConfig.aboutUsMobileImages}
            city={city}
          />
          <PriceListSection
            passedRef={priceListRef}
            prices={currentCityConfig.prices}
            bookingUrl={`/${city}/booking`}
            city={city}
            locations={locations}
          />
          <VoucherSection passedRef={voucherRef} city={city} />
          {isCooperationSectionVisible && (
            <CooperationSection passedRef={cooperationRef} city={city} />
          )}
          {shouldShowClassesSection && (
            <ClassesSection passedRef={classesRef} city={city} />
          )}
          <ReviewSection passedRef={reviewsRef} />
          <FAQSection passedRef={faqRef} city={city} />
          <CareerSection passedRef={careerRef} />
          <GoogleReCaptchaProvider
            reCaptchaKey={process.env.REACT_APP_NEWSLETTER_RECAPTCHA_KEY}
            container={{
              element: "recaptchaContainer",
              parameters: {
                badge: "bottomleft",
                size: "invisible",
              },
            }}
          >
            <NewsletterSection setIsLoading={setIsNewsletterRequestLoading} />
          </GoogleReCaptchaProvider>
        </div>
        <ContactSection
          passedRef={contactRef}
          address={currentCityConfig.address}
          email={currentCityConfig.email}
        />
        <img
          title="Shadow decoration"
          src={firstShadow}
          alt="first shadow decoration"
          className={css.firstShadow}
        />
      </div>
      <GoogleMaps {...mapOptions} />
      <Footer
        scrollCallback={() => selectSection(sections[0].title, homeRef)}
      />
    </div>
  );
};
